@font-face {
  font-family: 'ProximaNova';
  src: url('./assets/fonts/ProximaNova-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./assets/fonts/ProximaNova-semiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./assets/fonts/ProximaNova-bold.ttf') format('truetype');
  font-weight: 700;
}

* {
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'ProximaNova', Sans-serif;
}

[data-amplify-authenticator] {
  height: 100vh;
}